<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <vertical-nav-menu></vertical-nav-menu>
      <div class="nk-wrap">
        <nav-bar></nav-bar>
        <main-block>
          <block-content>
            <card class="px-2 py-1 mb-3" no-padding>
              <div class="d-flex align-items-center fw-500 nk-text-dark h6">
                <nk-button type="" is-icon-only-button="" round v-on:click="router.go(-1)" class="btn-trigger mr-3">
                  <nio-icon icon="ni-arrow-left"></nio-icon>
                </nk-button>
                <span>Viewing Lead # {{lead_details.lead_number}}</span>
              </div>
            </card>
            <row class="g-gs">
              <column :xxl="4">
                <row>
                  <column :md="6">
                    <b class="d-block text-primary">Lead #{{lead_details.lead_number}}</b>
                    <b class="nk-text-muted">{{ lead_details.form_name}}</b>
                  </column>
                  <column :md="6">
                    <div class="nk-text-muted">Received:</div>
                    <span class="form-control">Op: <b>{{ lead_details.received_date}}</b>, om: <b>{{ lead_details.received_time}}</b></span>
                  </column>
                </row>
                <row class="mt-2">
                  <column :md="6">
                    <span class="d-block nk-text-dark fw-500">{{lead_details.first_name+' '+lead_details.last_name}}</span>
                    <div class="nk-text-muted">{{ lead_details.city }}<br/>{{ lead_details.province}}<br/>{{lead_details.post_code}}</div>
                  </column>
                  <column :md="6">
                    <row class="no-gutters text-center lh-n">
                      <column :md="6" class="pr-1">
                        <card class="bg-success p-1" no-padding>
                          <div class="text-muted fw-500">Lead Score</div>
                          <div style="font-size: 2.1rem" class="text-white my-1">{{ lead_details.lead_score }}</div>
                          <div class="text-muted">High</div>
                        </card>
                      </column>
                      <column :md="6" class="pl-1">
                        <card class="bg-warning p-1" no-padding>
                          <div class="text-muted fw-500">Engagement</div>
                          <div style="font-size: 2.1rem" class="text-white my-1">2,5</div>
                          <div class="text-muted">Low</div>
                        </card>
                      </column>
                    </row>
                  </column>
                </row>
                <lead-data :lead_data="lead_details"></lead-data>
                <row class="mt-2">
                  <column default="12" class="text-primary fw-500" v-if="lead_details.marketplace.id > 0">
                    <span v-if="lead_details.marketplace.status === 1">The lead is sold through marketplace.</span>
                    <span v-else-if="lead_details.marketplace.status === 2">The lead is booked through marketplace.</span>
                    <span v-else>The lead is in the marketplace.</span>
                    <span> For &euro;{{ lead_details.marketplace.price }}.</span>
                  </column>
                  <column :md="6">
                    <nk-button type="dark" v-if="lead_details.marketplace.id < 1 && !lead_details.assigned_to.id"
                               v-on:click="addLeadToMarketplace">To marketplace</nk-button>
                    <nk-button type="dark" v-else-if="!lead_details.assigned_to.id && ![1,2].includes(lead_details.marketplace.status)"
                               v-on:click="withdrawLead">Withdraw</nk-button>
                  </column>
                  <column :md="6" class="text-right" v-if="lead_details.marketplace.id < 1 && !lead_details.assigned_to.id">
                    <nk-button type="danger" v-on:click="deleteLead">Delete</nk-button>
                  </column>
                </row>
              </column>

              <column :xxl="8" full-height>

              </column>

              <!-- lead on map -->
              <column :xxl="12">
                <card :full-height="true" no-padding>
                  <accordion id="lead_map" class="is-separate bg-transparent">
                    <accordion-item class="light-shadow mb-0" id="acc_lead_map">
                      <accordion-item-head data-target="#lead_map_content">
                        <div class="d-md-flex align-items-center">
                          <nio-icon icon="ni-map" class="mr-2 text-primary" style="font-size: 1.5rem"></nio-icon>
                          <div class="flex-fill">View in map</div>
                        </div>
                      </accordion-item-head>
                      <accordion-item-body id="lead_map_content" data-parent="#acc_lead_map" no-padding class="border-top">
                        <div class="p-4">
                          <row class="nk-text-dark">
                            <column :md="5">
                              <div class="font-weight-bold">{{ lead_details.city }}</div>
                              <div class="nk-text-muted lh-n">{{ lead_details.house_number }}, {{ lead_details.street_no }}<br/> {{ lead_details.post_code }}</div>
                            </column>
                          </row>
                        </div>
                        <lead-location-map
                            map-type="roadmap"
                            :start="lead_details.post_code+' '+lead_details.house_number+','+lead_details.city"
                            end=""></lead-location-map>
                      </accordion-item-body>
                    </accordion-item>
                  </accordion>
                </card>
              </column>

              <column :xxl="12">
                <card no-padding v-if="lead_details.marketplace.id < 1 && !lead_details.assigned_to.id">
                  <template v-slot:grouped>
                    <div class="card-inner position-relative card-tools-toggle">
                      <card-header title="">
                        <card-tools>
                          <div class="form-inline flex-nowrap gx-3">
                            <span class="nk-text-dark">Search</span>
                            <div class="form-wrap">
                              <input type="text" class="form-control" placeholder="Search by name"
                                     v-model="advisorsFilters.search" v-on:input="searchAdvisors"/>
                            </div>
                          </div>
                        </card-tools>
                        <card-tools>
                          <nk-button :type="advisorsFilters.closest ? 'primary' : 'light'"
                                     :outline="!advisorsFilters.closest" size="md" class="mr-2"
                                     v-on:click="filterAdvisors('closest')">Closest</nk-button>
                          <nk-button :type="advisorsFilters.less_leads ? 'primary' : 'light'"
                                     :outline="!advisorsFilters.less_leads" size="md"
                                     v-on:click="filterAdvisors('less_leads')">Lowest # Leads</nk-button>
                        </card-tools>
                      </card-header>
                      <card-search
                          placeholder="Search: lead number/id, advisor name/email"
                          :is-active="searchBarActive"
                          @do-search="doSearch"
                          :search-term="filters"
                          @close-search="[searchBarActive=false, doSearch('')]"></card-search>
                    </div>
                    <div class="card-inner p-0">
                      <div class="p-3" v-if="advisorsSkeleton">
                        <ion-skeleton-text animated class="w-75"></ion-skeleton-text>
                        <ion-skeleton-text animated class="w-75"></ion-skeleton-text>
                        <ion-skeleton-text animated class="w-50"></ion-skeleton-text>
                        <ion-skeleton-text animated class="w-25"></ion-skeleton-text>
                      </div>
                      <special-table v-else>
                        <table-row is-heading>
                          <table-col>Name</table-col>
                          <table-col break-point="mb">Distance</table-col>
                          <table-col break-point="md">Leads</table-col>
                          <table-col class="nk-tb-col-tools"></table-col>
                        </table-row>

                        <table-row v-for="advisor in advisors" :key="'ad'+advisor.id">
                          <table-col>
                            <user-small-card
                                :user-name="advisor.first_name+' '+advisor.last_name"
                                :image="advisor.dp" :initials="advisor.initials">
                              <template v-slot:more_info>
                                <span>{{ advisor.city}}</span>
                                <span class="small d-block">
                                  <span class="nk-text-muted" v-if="advisor.last_login.interval.length">Last seen: {{ advisor.last_login.interval}} ago, </span>
                                  <span class="fw-500 nk-text-muted">{{ advisor.last_login.date}}</span><span class="nk-text-muted"> at </span>
                                  <span class="fw-500 nk-text-muted">{{ advisor.last_login.time}}</span>
                                </span>
                              </template>
                            </user-small-card>
                          </table-col>
                          <table-col break-point="mb">
                            <span class="fw-500 text-success">{{ advisor.google_matrix.distance.text.split(' ')[0]}}</span>
                            <span class="fw-500 nk-text-muted text-uppercase mx-1">{{ advisor.google_matrix.distance.text.split(' ')[1]}}</span>
                            <span class="nk-text-muted small"> (+/- {{ advisor.google_matrix.duration.text }})</span>
                          </table-col>
                          <table-col break-point="md">
                            <span class="fw-500 text-success">{{ advisor.assigned_leads.current_week}}</span>
                            <span class="fw-500 text-dark">/{{ advisor.available_leads}}</span>
                            <span class="nk-text-muted"> this week</span><br/>
                            <a href="javascript:;" class="small text-muted"
                               v-on:click="viewAdvisorLastWeekLeadsCount(advisor)"
                               style="text-decoration: underline">+view last week</a>
                          </table-col>
                          <table-col>
                            <nk-button type="success" size="sm" v-on:click="assignLead(advisor)">
                              <nio-icon icon="ni-user-check"/> Assign lead
                            </nk-button>
                          </table-col>
                        </table-row>

                      </special-table>
                    </div>
                    <div class="card-inner" v-if="pagination.totalRecords > 10">
                      <pagination
                          v-model="pagination.page"
                          :records="pagination.totalRecords"
                          :per-page="10"
                          @paginate="paginationCallback"
                          theme="bootstrap4"
                          :options="{chunk: 5, texts:{count: '||'}}"/>
                    </div>
                  </template>
                </card>
                <card v-else-if="lead_details.assigned_to.id">
                  <card-header :title="lead_details.assigned_to.assigned_by_admin === 1 ? 'Lead is assigned to' : 'Lead is bought by'" class="mb-5"></card-header>
                  <row>
                    <column :lg="4">
                      <user-small-card
                          :user-name="lead_details.assigned_to.first_name+' '+lead_details.assigned_to.last_name"
                          :user-email="lead_details.assigned_to.email"
                          :image="lead_details.assigned_to.dp"
                          :initials="lead_details.assigned_to.initials"></user-small-card>
                    </column>
                    <column :lg="4">
                      <div class="fw-500 nk-text-dark">{{ (lead_details.assigned_to.assigned_by_admin === 1 ? 'Assigned on' : 'Bought on') }}</div>
                      <span class="text-muted">{{ lead_details.assigned_to.assign_date}}</span>
                    </column>
                    <column :lg="4">
                      <div class="fw-500 nk-text-dark">Status</div>
                      <span v-if="lead_details.assigned_to.returned === 1" class="text-danger">The lead is returned</span>
                      <span v-else class="text-muted" :style="{color: lead_details.assigned_to.status_color}">{{ lead_details.assigned_to.status_text}}</span>
                    </column>
                  </row>
                </card>
              </column>

            </row>
          </block-content>
        </main-block>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {alertController, IonContent, IonPage, loadingController, toastController, IonSkeletonText} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch } from 'vue';
import VerticalNavMenu from "@/@core/layouts/vertical-nav-menu/VerticalNavMenu";
import NavBar from "@core/layouts/app-nav-bar/NavBar";
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import { Row, Column } from '@core/layouts';
import { Card, CardHeader, CardTools, CardSearch } from '@core/components/cards'

import "bootstrap"

import LeadData from "@/views/lead-details/LeadData";
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemHead} from "@core/components/accordion"
import LeadLocationMap from "@/views/lead-details/LeadLocationMap";
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";

import {SpecialTable, TableRow, TableCol} from "@core/components/special-table";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";

import useMarketplace from "./useMarketplace";
import useDeleteLeads from "./useDeleteLeads";

import axios from "@/libs/axios";
import { useRoute } from "vue-router"
import { useRouter} from "vue-router"
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"
import Pagination from "v-pagination-3"

export default defineComponent({
  name: 'Home',
  components: {
    NkButton,
    NioIcon,
    LeadLocationMap,
    AccordionItemBody,
    AccordionItemHead,
    AccordionItem,
    Accordion,
    LeadData,
    Card,
    CardHeader,
    CardTools,
    CardSearch,
    Row,
    Column,
    BlockContent,
    MainBlock,
    NavBar,
    Pagination,
    VerticalNavMenu,
    IonContent,
    IonPage,
    IonSkeletonText,
    SpecialTable, TableRow, TableCol,
    UserSmallCard,
    /*IonHeader,
    IonTitle,
    IonToolbar,*/
  },
  setup() {

    const route  = useRoute()
    const router = useRouter()
    const { updateTracker } = useDataChangeTracker()

    // Advisors
    // Advisors list and related logic

    let advisors = ref([]) // Store advisors list in this array
    // Advisors list filters
    let advisorsFilters = reactive({
      search: '',
      closest: false, // Advisors closes (in distance) to this lead
      less_leads: false, // Advisors assigned less leads to
    })
    // Function to handle the search input
    const searchAdvisors = () => {
      advisorsFilters.closest = advisorsFilters.less_leads = false
      getAdvisors()
    }
    // Function to handle the other advisor filters
    const filterAdvisors = (filter) => {
      advisorsFilters.search = ''
      advisorsFilters.closest = advisorsFilters.less_leads = false
      advisorsFilters[filter] = true
      getAdvisors()
    }
    // Advisors pagination logic
    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      await getAdvisors()
    }
    // Get advisors from server
    let advisorsSkeleton = ref(false)
    let cancelToken = false
    const getAdvisors = async () => {

      advisorsSkeleton.value = true

      if(false !== cancelToken){
        cancelToken()
        setTimeout(()=> advisorsSkeleton.value = true, 200)
        cancelToken = false
      }

      const data = {
        lead_id: route.params.id,
        filter: (advisorsFilters.closest) ? 'closest' : (advisorsFilters.less_leads ? 'assigned_less_leads' : ''),
        search: advisorsFilters.search,
        page: pagination.page,
      }
      axios.post('/api/admin/advisors/list/lead_assignment', data, {
        cancelToken: new axios.CancelToken( c => {
          cancelToken = c
        })
      })
          .then( (resp) => {
            if(resp.data.error){
              console.log('Lead details error: '+resp.data.error);
            }
            else{
              advisors.value = []
              for(let x in resp.data.data){
                advisors.value.push(resp.data.data[x])
              }
            }
          })
          .catch( err => {
            console.log('Advisors list ajax: '+err)
          })
          .then(() => {
            cancelToken = false
            advisorsSkeleton.value = false
          })
    }
    // View an advisor last week assigned leads
    const viewAdvisorLastWeekLeadsCount = async (advisor) => {

      const assignedLeads = parseInt(advisor.assigned_leads.last_week)

      const iAlert = await alertController.create({message: '', buttons: ['OK']})
      iAlert.header = 'Last week leads'
      iAlert.message = `${assignedLeads > 0 ? assignedLeads : 'No'} ${assignedLeads > 1 ? 'leads were' : 'lead was'} assigned to ${advisor.first_name} in the last week.`
      await iAlert.present()
    }

    // Lead related logic

    let lead_details    = reactive({
          assigned_to: {
            assign_date: '',
            assigned_by_admin: 0,
            dp: '',
            email: '',
            first_name: '',
            initials: '',
            last_name: '',
            returned: 0,
            status_color: '',
            status_text: '',
          },
          house_number: '',
          status_id: 0,
          event_date_iso: '',
          event_date: '',
          first_name: '',
          id: 0,
          last_name: '',
          marketplace: {
            id: 0,
            price: '',
            status: 0,
          },
          notes: [],
          city: '',
          post_code: '',
        })
    // Get lead details from the server
    const getLeadDetails = async () => {
      axios.get('/api/admin/leads/details/'+route.params.id)
          .then( (resp) => {
            if(resp.data.error){
              console.log('Lead details error: '+resp.data.error);
            }
            else{
              for(let x in resp.data.data){
                lead_details[x] = resp.data.data[x];
              }
            }
          })
          .catch( err => {
            console.log('Lead details ajax: '+err)
          })
    }
    // Assign this lead to an advisor
    const assignLead = async ( advisor ) => {

      const loading = await loadingController.create({message: 'Processing...'})
      const toast   = await toastController.create({position: "top", duration: 3000})

      let iAlert = await alertController.create({
        cssClass: 'text-lg-left',
        header: `Assign Lead`,
        message: 'Amount to add to advisor invoice: &euro;',
        inputs: [
          {
            name: 'price',
            type: 'text',
            cssClass: 'mt-0',
          }
        ],
        buttons: [
          'Cancel',
          {
            text: 'Confirm', handler: (d) => {

              let price = parseFloat(d.price.trim());
              if(!price || price < 1){
                toastController.create({position: "top", duration: 3000, message: 'Please provide a valid price.', color: "danger"}).then((t) => t.present())
                return false
              }

              loading.present()
              axios.post('/api/admin/leads/assign', {advisor_id: advisor.id, lead_id: route.params.id, price: price})
                  .then( resp => {
                    toast.message = resp.data.success ? 'Thank you! lead assigned to advisor, you can view it under the assigned leads.' : resp.data.message
                    toast.color   = resp.data.success ? "primary" : "danger"
                    if( resp.data.success ){
                      // Trigger data change
                      updateTracker('admin', ['assigned_leads', 'leads_list'])
                      for(let x in resp.data.data.assigned_to){
                        lead_details.assigned_to[x] = resp.data.data.assigned_to[x]
                      }
                    }
                    toast.present()
                  })
                  .catch(error => {
                    toast.message = 'Error: '+error.response.status+' '+error.response.statusText
                    toast.color = "danger"
                    toast.present()
                  })
                  .then( () => loading.dismiss())
            }
          }
        ]
      })
      await iAlert.present()
    }
    // Adding and withdrawing lead from the marketplace
    const { addToMarketplace, withdrawLeads } = useMarketplace()
    const withdrawLead = () => {
      withdrawLeads([lead_details], (success) => {
        if( success ){
          updateTracker('admin', ['marketplace'])
          lead_details.marketplace.id = 0
        }
      })
    }
    const addLeadToMarketplace = () => {
      addToMarketplace([lead_details], (success, marketplaceIds, price ) => {
        if(success){
          updateTracker('admin', ['leads_list', 'marketplace'])
          lead_details.marketplace.id = marketplaceIds[0]
          lead_details.marketplace.price = price
        }
      })
    }
    // Deleting the lead
    const { deleteRecords } = useDeleteLeads()
    const deleteLead = () => {
      deleteRecords([lead_details], 'lead',(success) => {
        if(success){
          updateTracker('admin', ['assigned_leads', 'leads_list', 'marketplace'])
          router.go(-1)
        }
      })
    }

    onMounted(()=>{
      getLeadDetails()
      getAdvisors()
    })
    // If the lead changes then load its data
    watch( () => route.params.id, (n) => {
      if(undefined !== n){
        getLeadDetails()
        getAdvisors()
      }
    })

    return {
      addLeadToMarketplace,
      advisors,
      advisorsFilters,
      advisorsSkeleton,
      assignLead,
      deleteLead,
      filterAdvisors,
      lead_details,
      pagination,
      paginationCallback,
      router,
      searchAdvisors,
      viewAdvisorLastWeekLeadsCount,
      withdrawLead,
    }
  }
});
</script>
