import {alertController, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";

export default () => {

    const deleteRecords = async ( _records, record_type, callback ) => {

        const loading = await loadingController.create({message: 'Processing...'})
        const toast   = await toastController.create({position: "top", duration: 3000})

        let ids = []
        for(let x in _records){
            ids.push( _records[x].id)
        }

        let iAlert = await alertController.create({
            message: `Are you sure to delete the ${_records.length} selected ${record_type+(_records.length > 1 ? 's' : '')}?`,
            buttons: [
                'Cancel',
                {
                    text: 'Confirm', handler: () => {
                        loading.present()
                        axios.post('/api/admin/leads/delete', {ids: JSON.stringify(ids)})
                            .then( resp => {
                                toast.message = resp.data.success ? 'Thank you! action completed' : resp.data.message
                                toast.color   = resp.data.success ? "primary" : "danger"
                                toast.present()
                                callback(resp.data.success)
                            })
                            .catch(error => {
                                toast.message = 'Error: '+error.response.status+' '+error.response.statusText
                                toast.color = "danger"
                                toast.present()
                                callback(false)
                            })
                            .then( () => loading.dismiss())
                    }
                }
            ]
        })
        await iAlert.present()
    }

    return {
        deleteRecords,
    }
}