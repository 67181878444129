import {alertController, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";

export default () => {

    /**
     * Add one or more leads to the marketplace
     * @param _leads array having leads (objects)
     * @param callback the call back to call when ajax is completed. arguments (success[true|false], marketplace ids, price)
     * @returns {Promise<void>}
     */
    const addToMarketplace = async ( _leads, callback ) => {

        const loading = await loadingController.create({message: 'Processing...'})
        const toast   = await toastController.create({position: "top", duration: 3000})

        let ids = []
        for(let x in _leads){
            ids.push( _leads[x].id)
        }

        let iAlert = await alertController.create({
            cssClass: 'text-lg-left',
            header: `Add lead${ids.length > 1 ? 's' : ''} to marketplace`,
            message: 'Please provide price &euro;',
            inputs: [
                {
                    name: 'price',
                    type: 'text',
                    cssClass: 'mt-0',
                }
            ],
            buttons: [
                'Cancel',
                {
                    text: 'Confirm', handler: (d) => {

                        let price = parseFloat(d.price.trim());
                        if(!price || price < 1){
                            toastController.create({position: "top", duration: 3000, message: 'Please provide a valid price.', color: "danger"}).then((t) => t.present())
                            return false
                        }

                        loading.present()
                        axios.post('/api/admin/marketplace/add', {ids: JSON.stringify(ids), price: price})
                            .then( resp => {
                                toast.message = resp.data.success ? 'Thank you! leads added to marketplace, you can view it under the marketplace.' : resp.data.message
                                toast.color   = resp.data.success ? "primary" : "danger"
                                toast.present()
                                callback(resp.data.success, resp.data.data, price)
                            })
                            .catch(error => {
                                toast.message = 'Error: '+error.response.status+' '+error.response.statusText
                                toast.color = "danger"
                                toast.present()
                                callback(false)
                            })
                            .then( () => loading.dismiss())
                    }
                }
            ]
        })
        await iAlert.present()
    }

    /**
     * Withdraw one or more leads from the marketplace
     * @param _leads array having leads (objects)
     * @param callback the call back to call when ajax is completed
     * @returns {Promise<void>}
     */
    const withdrawLeads = async ( _leads, callback ) => {

        const loading = await loadingController.create({message: 'Processing...'})
        const toast   = await toastController.create({position: "top", duration: 3000})

        let ids = []
        for(let x in _leads){
            ids.push( _leads[x].id)
        }

        const iAlert = await alertController.create({
            cssClass: 'text-lg-left',
            header: `Withdraw lead${ids.length > 1 ? 's' : ''}`,
            message: `Are you sure to withdraw ${ids.length > 1 ? ' the selected leads' : 'this lead'} from the marketplace?`,
            buttons: [
                'Cancel',
                {
                    text: 'Confirm', handler: () => {

                        loading.present()
                        axios.post('/api/admin/marketplace/withdraw', {ids: JSON.stringify(ids)})
                            .then( resp => {
                                toast.message = resp.data.success ? 'Thank you! leads withdrawn, you may see it in the leads list.' : resp.data.message
                                toast.color   = resp.data.success ? "primary" : "danger"
                                toast.present()
                                callback(resp.data.success)
                            })
                            .catch(error => {
                                toast.message = 'Error: '+error.response.status+' '+error.response.statusText
                                toast.color = "danger"
                                toast.present()
                                callback(false)
                            })
                            .then( () => loading.dismiss())
                    }
                }
            ]
        })
        await iAlert.present()
    }

    return {
        addToMarketplace,
        withdrawLeads,
    }
}