import {useStore} from "vuex";

export default () => {
    const store = useStore()
    const updateTracker = ( _interface, trackers, status ) => {
        if(undefined === status){
            status = true
        }
        const mutation = _interface === 'admin' ? 'UPDATE_ADMIN_TRACKER' : 'UPDATE_ADVISOR_TRACKER'
        for(let x in trackers){
            store.commit(`dataChangeTracker/${mutation}`, {tracker: trackers[x], status: status})
        }
    }

    return {
        updateTracker,
    }
}